const combineTables = (tables) => {
  const combinedData = {
    hasData: true,
    sid: 'all',
    teams: {},
  };

  tables.forEach((table) => {
    Object.entries(table.teams).map(([pid, team]) => {
      if (!combinedData.teams[pid]) {
        combinedData.teams[pid] = {
          draws: 0,
          losses: 0,
          pid: team.pid,
          totalPoints: 0,
          totalScore: 0,
          wins: 0,
          seasons: 0,
        };
      }

      combinedData.teams[pid].draws += team.draws;
      combinedData.teams[pid].losses += team.losses;
      combinedData.teams[pid].totalPoints += team.totalPoints;
      combinedData.teams[pid].totalScore += team.totalScore;
      combinedData.teams[pid].wins += team.wins;
      combinedData.teams[pid].seasons += 1;

      return combinedData;
    });
  });

  return combinedData;
};

export default combineTables;
