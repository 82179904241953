/* eslint-disable max-len */
import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import TocIcon from '@mui/icons-material/Toc'; // Superleague
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'; // Seasons/History
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'; // Home
// import Groups2Icon from '@mui/icons-material/Groups2'; //Fixtures
// import HandshakeIcon from '@mui/icons-material/Handshake'; // Results

import styles from './bottomNav.module.css';

const useRouteMatch = (patterns) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
};

function BottomNav() {
  const routeMatch = useRouteMatch(['/', '/history', '/superleague']);
  const current = routeMatch?.pattern?.path;

  return (
    <Box className={styles.root}>
      <BottomNavigation
        value={current}
        className={styles.nav}
      >
        <BottomNavigationAction label="Home" icon={<SportsSoccerIcon />} value="/" to="/" component={Link} />
        {/* <BottomNavigationAction label="Fixtures" icon={<Groups2Icon />} to={'/fixtures'} component={Link} />
        <BottomNavigationAction label="Results" icon={<HandshakeIcon />} to="/results" component={Link} /> */}
        <BottomNavigationAction label="History" icon={<EmojiEventsIcon />} value="/history" to="/history" component={Link} />
        <BottomNavigationAction label="Superleague" icon={<TocIcon />} value="/superleague" to="/superleague" component={Link} />
      </BottomNavigation>
    </Box>
  );
}

export default BottomNav;
