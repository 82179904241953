const useSortedTeams = (teams) => {
  if (!teams) {
    return [];
  }

  const sortedTeams = Object.entries(teams).sort(
    ([, teamA], [, teamB]) => {
      if (teamB.totalPoints === teamA.totalPoints) {
        return teamB.totalScore - teamA.totalScore;
      }

      return teamB.totalPoints - teamA.totalPoints;
    },
  );

  return sortedTeams;
};

export default useSortedTeams;
