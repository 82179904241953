// @flow
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  styled,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import firebase from '../../firebase';

import combineTables from '../../utils/combineTables';
import useSortedTeams from '../../hooks/useSortedTeams';

import SeasonSelect from '../SeasonSelect';
import Loader from '../Loader';

import styles from './table.module.css';

const db = firebase.database();

function Standings() {
  const [season, setSeason] = useState(5);
  const [tableData, setTableData] = useState({});
  const [players, setPlayers] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCombinedData = async () => {
      const allTablesSnap = await db.ref('tables').once('value');
      const combinedData = combineTables(allTablesSnap.val());
      setTableData(combinedData);
      setLoading(false);
    };

    const fetchData = async () => {
      const tableSnapshot = await db.ref(`tables/${season}`).once('value');
      const playersSnapshot = await db.ref('players').once('value');
      setTableData(tableSnapshot.val());
      setPlayers(playersSnapshot.val());
      setLoading(false);
    };

    if (season === 'all') {
      fetchCombinedData();
    } else {
      fetchData();
    }
  }, [season]);

  const updateSeason = (value) => {
    setSeason(value);
  };

  const teams = useSortedTeams(tableData?.teams);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.thead,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SeasonSelect activeSeason={season} handleChange={updateSeason} />

      <TableContainer className={styles.root} component={Paper}>
        <Table aria-label="League Standings Table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Pos.</StyledTableCell>
              <StyledTableCell>Team</StyledTableCell>
              {season === 'all' && (
                <StyledTableCell align="right">Seasons</StyledTableCell>
              )}
              <StyledTableCell align="right">Score</StyledTableCell>
              <StyledTableCell align="right">Pts</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && teams.map(([teamID, team], i) => (
              <StyledTableRow
                key={teamID}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell>{i + 1}</StyledTableCell>
                <StyledTableCell>{players[`player_${team.pid}`]?.TeamName}</StyledTableCell>
                {season === 'all' && (
                  <StyledTableCell align="right">{team.seasons}</StyledTableCell>
                )}
                <StyledTableCell align="right">{team.totalScore}</StyledTableCell>
                <StyledTableCell align="right">{team.totalPoints}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Standings;
