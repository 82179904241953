import React from 'react';
import Table from '../components/Table';
import Layout from '../components/Layout';

function Seasons() {
  return (
    <Layout>
      <div>
        <h1>Seasons</h1>
        <Table />
      </div>
    </Layout>
  );
}

export default Seasons;
