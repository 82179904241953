import firebase from '../firebase';

const db = firebase.database();
const playersRef = db.ref('players');

const getPlayer = async (playerId) => {
  const playersSnapshot = await playersRef.orderByChild('fpl_entry_id').equalTo(playerId).once('value');
  const playerData = playersSnapshot.val();
  const playerKey = Object.keys(playerData)[0];
  return playerData[playerKey];
};

export default getPlayer;
