import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Home from './routes/Home';
import Superleague from './routes/Superleague';
import Fixtures from './routes/Fixtures';
import Seasons from './routes/Seasons';
import Results from './routes/Results';

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'superleague',
    element: <Superleague />,
  },
  {
    path: 'fixtures',
    element: <Fixtures />,
  },
  {
    path: 'history',
    element: <Seasons />,
  },
  {
    path: 'results',
    element: <Results />,
  },
];

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
