import React from 'react';
import {
  Select as MuiSelect,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';

function Select({
  name,
  label,
  options,
  selected,
  onChange,
}) {
  const labelId = label.replace(/\s+/g, '-');
  const selectId = name.replace(/\s+/g, '-');

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect
        labelId={labelId}
        id={selectId}
        value={selected}
        label={label}
        onChange={handleChange}
      >
        {options && options.map((option) => (
          <MenuItem key={`${option.name}`} value={option.value}>{option.name}</MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
