import React from 'react';
import Layout from '../components/Layout';

function Results() {
  return (
    <Layout>
      <div>
        <h1>Results</h1>
      </div>
    </Layout>
  );
}

export default Results;
