/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import getLeagueData from '../utils/getLeagueData';
import getSuperLeague from '../utils/getSuperLeague';
import formatLeagueData from '../utils/formatLeagueData';

import NewTable from '../components/NewTable';

function Superleague() {
  const [loading, setLoading] = useState(true);
  const [fplLeagueData, setFplLeagueData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const superleagueData = await getSuperLeague();
      setFplLeagueData(superleagueData);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Layout>
      {loading && (
        <Loader />
      )}

      {!loading && fplLeagueData && (
        <>
          <h1>Superleague</h1>
          <NewTable data={fplLeagueData} superleague />
        </>
      )}
    </Layout>
  );
}

export default Superleague;
