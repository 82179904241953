import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#b0bec5',
      light: '#4a6572',
      dark: '#232f34',
      thead: '#000814',
    },
    secondary: {
      main: '#f9aa33',
    },
    background: {
      default: '#344955',
      paper: '#232f34',
    },
    text: {
      secondary: '#FFB74D',
    },
  },
});

export default theme;
