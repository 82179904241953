import axios from 'axios';
import getPlayer from './getPlayer';

const getSuperLeague = async () => {
  const east = await axios.get(`https://draft.premierleague.com/api/league/${process.env.REACT_APP_EAST_ID}/details`);
  const west = await axios.get(`https://draft.premierleague.com/api/league/${process.env.REACT_APP_WEST_ID}/details`);

  const combinedData = [east.data, west.data];

  let allData = [];

  // Wait for all the asynchronous operations inside the map to complete
  await Promise.all(combinedData.map(async (division) => {
    const leagueData = division.standings;

    const playersAndEntries = await Promise.all(leagueData.map(async (entry) => {
      const fplPlayer = division.league_entries.find(({ id }) => id === entry.league_entry);
      const player = await getPlayer(fplPlayer.entry_id);
      player.liveName = fplPlayer.entry_name;
      return { player, entry };
    }));

    const divisionData = playersAndEntries.map(({ player, entry }) => {
      if (player) {
        return {
          teamName: player.TeamName,
          liveName: player.liveName,
          draws: entry.matches_drawn,
          losses: entry.matches_lost,
          pid: player.pid,
          totalPoints: entry.total,
          totalScore: entry.points_for,
          wins: entry.matches_won,
        };
      }
      return null;
    }).filter(Boolean);

    allData = allData.concat(divisionData);
  }));

  const sortedData = allData.sort((a, b) => (
    b.totalScore - a.totalScore
  ));

  return sortedData;
};

export default getSuperLeague;
