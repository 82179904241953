// @flow
/* eslint-disable no-unused-vars */
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import React from 'react';

import styles from './table.module.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.thead,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function NewTable({
  data,
  type = 'historical',
  league,
  superleague = false,
}) {
  const teams = Object.values(data);

  return (
    <TableContainer className={styles.root} component={Paper}>
      <Table aria-label="League Standings">
        <TableHead>
          <TableRow>
            <StyledTableCell>Pos.</StyledTableCell>
            <StyledTableCell>Team</StyledTableCell>
            <StyledTableCell>W</StyledTableCell>
            <StyledTableCell>D</StyledTableCell>
            <StyledTableCell>L</StyledTableCell>
            <StyledTableCell align="right">Score</StyledTableCell>
            {!superleague && (
              <StyledTableCell align="right">Points</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {teams && teams.map((team, i) => (
            <StyledTableRow
              key={team.pid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell>{i + 1}</StyledTableCell>
              <StyledTableCell>{(team.liveName) ? team.liveName : team.teamName}</StyledTableCell>
              <StyledTableCell>{team.wins}</StyledTableCell>
              <StyledTableCell>{team.draws}</StyledTableCell>
              <StyledTableCell>{team.losses}</StyledTableCell>
              <StyledTableCell align="right">{team.totalScore}</StyledTableCell>
              {!superleague && (
                <StyledTableCell align="right">{team.totalPoints}</StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NewTable;
