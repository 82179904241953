/* eslint-disable no-unused-vars */
import getPlayer from './getPlayer';

const formatLeagueData = async (data, source) => {
  if (source !== 'fpl_api') {
    return data;
  }
  const leagueData = data.standings;
  const newData = {};

  const playersAndEntries = await Promise.all(leagueData.map(async (entry) => {
    const fplPlayer = data.league_entries.find(({ id }) => id === entry.league_entry);
    const player = await getPlayer(fplPlayer.entry_id);
    player.liveName = fplPlayer.entry_name;
    return { player, entry };
  }));

  playersAndEntries.forEach(({ player, entry }) => {
    if (player) {
      newData[`pid${player.pid}`] = {
        teamName: player.TeamName,
        liveName: player.liveName,
        draws: entry.matches_drawn,
        losses: entry.matches_lost,
        pid: player.pid,
        totalPoints: entry.total,
        totalScore: entry.points_for,
        wins: entry.matches_won,
      };
    }
  });
  return newData;
};

export default formatLeagueData;
