import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: 'AIzaSyAnC7aylRfP6-ANux_SsMSAh_3ldWhVXOM',
  authDomain: 'ais-fpl-superleague.firebaseapp.com',
  databaseURL: 'https://ais-fpl-superleague-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ais-fpl-superleague',
  storageBucket: 'ais-fpl-superleague.appspot.com',
  messagingSenderId: '776420583936',
  appId: '1:776420583936:web:1ce0db3075e5db02a906e5',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
