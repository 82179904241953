import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Container, Box } from '@mui/material';
import theme from '../../theme';
import BottomNav from '../BottomNav';

import styles from './layout.module.css';

function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.root}>
        <Box sx={{ backgroundColor: 'background.paper', color: 'text.primary' }} className={styles.content}>
          <Container>
            {children}
          </Container>
        </Box>
        <BottomNav />
      </div>
    </ThemeProvider>
  );
}

export default Layout;
