import React, { useEffect, useState } from 'react';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import firebase from '../../firebase';

const db = firebase.database();

function SeasonSelect({ activeSeason, handleChange }) {
  const [seasons, setSeasons] = useState({});

  useEffect(() => {
    const fetchAllSeasons = async () => {
      const allSeasonsSnap = await db.ref('seasons').once('value');
      setSeasons(allSeasonsSnap.val());
    };

    fetchAllSeasons();
  }, [seasons]);

  const handleSeasonChange = (e) => {
    handleChange(e.target.value);
  };

  return (
    <FormControl>
      <InputLabel id="season-select-label">Season</InputLabel>
      <Select
        labelId="season-select-label"
        id="season-select"
        value={activeSeason}
        label="Season"
        onChange={handleSeasonChange}
      >
        <MenuItem value="all">All Seasons</MenuItem>
        {seasons && Object.entries(seasons).map(([seasonId, season]) => (
          <MenuItem key={seasonId} value={seasonId}>{season.Year}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SeasonSelect;
