import axios from 'axios';

const getLeagueData = async (league = 'east') => {
  const leagueId = (league === 'east') ? process.env.REACT_APP_EAST_ID : process.env.REACT_APP_WEST_ID;
  const url = `https://draft.premierleague.com/api/league/${leagueId}/details`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return false;
  }
};

export default getLeagueData;
