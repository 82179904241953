/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import firebase from '../firebase';
import Loader from '../components/Loader';
import getLeagueData from '../utils/getLeagueData';
import formatLeagueData from '../utils/formatLeagueData';

import NewTable from '../components/NewTable';
import Select from '../components/Select';

const db = firebase.database();

function Home() {
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [fplLeagueData, setFplLeagueData] = useState(null);
  const [division, setDivision] = useState('east');

  useEffect(() => {
    const fetchData = async () => {
      const playersSnapshot = await db.ref('players').once('value');
      const leagueData = await getLeagueData(division);
      const formattedData = await formatLeagueData(leagueData, 'fpl_api');
      setPlayers(playersSnapshot.val());
      setFplLeagueData(formattedData);
      setLoading(false);
    };

    fetchData();
  }, [division]);

  const selectOptions = [
    {
      name: 'East',
      value: 'east',
    },
    {
      name: 'West',
      value: 'west',
    },
  ];

  const handleSelect = (value) => {
    setDivision(value);
  };

  return (
    <Layout>
      {loading && (
        <Loader />
      )}

      {!loading && fplLeagueData && (
        <>
          <h1>Season 2023/2024</h1>
          <Select options={selectOptions} selected={division} name="Division Select" label="Division Select Label" onChange={handleSelect} />
          <NewTable data={fplLeagueData} />
        </>
      )}
    </Layout>
  );
}

export default Home;
